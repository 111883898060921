import { Menu } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label';

import InventoryIcon from "@mui/icons-material/Inventory";
import GavelIcon from "@mui/icons-material/Gavel";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import HistoryIcon from "@mui/icons-material/History";
import ShareIcon from "@mui/icons-material/Share";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ArchiveIcon from "@mui/icons-material/Archive";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';


export const MyMenu = () => (
    <Menu>
 {(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin"|| localStorage.getItem('username') == "natavega") &&
        <Menu.Item to="/transactions" primaryText="Locker Transactions"  leftIcon={<HistoryIcon />} />
    }
    <Menu.Item  to="/countryReport" primaryText="Country Report" leftIcon={<TravelExploreIcon />}/>
    <Menu.Item to="/locations" primaryText="Locations" leftIcon={<EditLocationAltIcon />}/>
        <Menu.Item to="/lockerstatus" primaryText="Locker Status"    leftIcon={<InventoryIcon />} />
        {/* {(localStorage.getItem('username') == "admin" || localStorage.getItem('username') == "superadmin") &&
        <Menu.Item to="/lockerprice" primaryText="Locker Price" leftIcon={<LabelIcon />}/>
    } */}
        <Menu.Item to="/sendsms" primaryText="Send SMS" leftIcon={<SendToMobileIcon />}/>
        <Menu.Item to="/checkin" primaryText="Check-in" leftIcon={<DomainVerificationIcon />}/>
        <Menu.Item to="/campaigns" primaryText="Campaign Coupons"    leftIcon={<InventoryIcon />} />
    </Menu>
);